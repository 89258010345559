<template>
  <div class="invoice--wrapper pa-4" v-if="documentContent">
    <div class="invoice--watermark" v-if="documentContent.paid_at">
      <div class="inner--watermark">
        <img
          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/icon-transparent.png"
        />
        <div>{{ $t('invoice.paid') }}</div>
      </div>
    </div>
    <div class="row align-center">
      <div class="col-auto">
        <img :src="logoUrl" alt="Rentfix Logo" height="50" />
      </div>
      <div class="col text-center">
        <div class="documentContent--title">
          {{ $t('invoice.buyInvoice') }}
        </div>
      </div>
    </div>
    <div class="top--section my-4">
      <div class="row">
        <div class="col-4 px-5">
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.date') }}:</div>
            <div>{{ $date.format(documentContent.created_at, 'dd MMMM yyyy') }}</div>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-6 px-5">
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.invoiceNumber') }}:</div>
            <div>{{ documentContent.invoice_number }}</div>
          </div>
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.payerClientName') }}:</div>
            <div>{{ documentContent.issuer }}</div>
          </div>
          <div class="d-flex justify-space-between mb-1" v-if="documentContent.payment_method">
            <div class="bold--text">{{ $t('order.paymentMethod') }}:</div>
            <div>{{ documentContent.payment_method }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle--section mb-4">
      <div class="bg--grey px-3 py-2">
        <div class="documentContent--title">
          {{ $t('invoice.packageSummary') }}
        </div>
      </div>
      <div v-if="documentContent.items">
        <div
          class="px-3 pb-2"
          v-for="(item, index) in documentContent.items"
          :key="`invoice-item-${index}`"
        >
          <div class="pa-4">
            <div class="mb-3">{{ $t('invoice.packageName') }}: {{ item.name }}</div>
            <div class="mb-3">
              <span>{{ $t('invoice.packagePrice') }}: </span>
              <span v-if="item.discount_sum && item.discount_sum > 0">
                <span
                  class="ml-2 mr-2"
                  style="text-decoration: line-through; color: #999999; font-size: 16px"
                  >{{ $n(item.price_sum, 'currency', 'id-ID') }}</span
                >
                <span>{{ $n(item.price_sum - item.discount_sum, 'currency', 'id-ID') }}</span>
              </span>
              <span v-else>{{ $n(item.price_sum, 'currency', 'id-ID') }}</span>
            </div>
            <div class="mb-3" v-if="item.active_at">
              {{ $t('invoice.activeAt') }}: {{ item.active_at }}
            </div>
            <div class="mb-3" v-if="item.expired_at">
              {{ $t('invoice.expiredAt') }}: {{ item.expired_at }}
            </div>
            <div>{{ $t('invoice.qty') }}: {{ item.qty }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="bottom--section mb-1">
          <div class="bg--grey px-3 py-2">
            <div class="documentContent--title">
              {{ $t('invoice.transactionSummary') }}
            </div>
          </div>
          <div class="pa-3">
            <div class="row pa-0">
              <div class="col-6">
                <span>{{ $t('invoice.subtotalPrice') }}</span>
              </div>
              <div class="col-6">
                <span>{{ $n(documentContent.sub_total_price, 'currency', 'id-ID') }}</span>
              </div>
            </div>
            <div class="row" v-if="documentContent.vat">
              <div class="col-6">
                <span class="bold--text">{{ $t('invoice.vat') }}</span>
              </div>
              <div class="col-6">
                <span class="bold--text">{{ $n(documentContent.vat, 'currency', 'id-ID') }}</span>
              </div>
            </div>
            <div class="row" v-if="documentContent.promo">
              <div class="col-6">
                <span class="promo--text">{{
                  `${$t('invoice.promo')}: ${documentContent.promo.promo_code}`
                }}</span>
              </div>
              <div class="col-6">
                <span class="promo--text" style="margin-left: -10px">{{
                  `- ${$n(documentContent.promo.promo_discount, 'currency', 'id-ID')}`
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="total--section py-2 px-3 bg--grey">
          <div class="row pa-0 align-center">
            <div class="col-6 documentContent--subtitle">
              <div>{{ $t('invoice.paidTotal') }}</div>
            </div>
            <div class="col-6 bold--text">
              {{ $n(documentContent.total_net_price, 'currency', 'id-ID') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paid--section row mt-4">
      <div class="col-6"></div>
      <div class="col-6 px-4" v-if="documentContent.paid_at">
        {{ $t('invoice.paidAt') }}:
        {{ $date.format(documentContent.paid_at, 'dd MMMM yyyy HH:mm') }}
      </div>
    </div>
    <div class="footer--section">Invoice: {{ documentContent.invoice_number }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      logoUrl: (state) => state.global.logoUrl,
      isLoggedIn: (state) => state.global.loggedIn,
      token: (state) => state.global.token,
      documentContent: (state) => state.order.invoice,
    }),
  },
  mounted() {
    // console.log('mountedd: ', this.documentContent);
    // this.$store.dispatch('v2/booking/detail/transactionDocument/getInvoice', {
    //   orderNumber: this.$route.query.order_number,
    //   token: this.isLoggedIn ? this.token : this.$route.query.token,
    //   feKey: this.$route.query.fe_key,
    // });
  },
};
</script>
